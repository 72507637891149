import "styles/globals.css";

import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

interface CustomAppProps extends AppProps {
  pageProps: {
    initialSession: any;
    openGraphData: any;
  };
}

export default function App({ Component, pageProps }: CustomAppProps) {
  const [supabaseClient] = React.useState(() => createBrowserSupabaseClient());

  const defaultOpenGraphData = {
    "og:title": "Zuma.Live ★ A virtual marketplace and tech platform",
    "og:type": "website",
    "og:url": process.env.NEXT_PUBLIC_CLIENT_URL,
    "og:image":
      "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/general/Social%20Share%20Image%20Zuma%20Live.jpeg",
    "og:image:alt": `Zuma Live photo of crowd`,
    "og:site_name": "Zuma Live",
    "og:image:width": "900",
    "og:image:height": "450",
    "og:image:secure_url":
      "https://glievsbwngosqvrxtupy.supabase.co/storage/v1/object/public/general/Social%20Share%20Image%20Zuma%20Live.jpeg",
    "og:description":
      "Zuma.Live: Empowering Creators, Innovators, Event Seekers, NFTs and More - All on a Global Scale",
  };

  const queryClient = new QueryClient();

  /** Grab any Open Graph data passed in to make the page look cool */
  const { openGraphData = {} } = pageProps;

  const ogData = { ...defaultOpenGraphData, ...openGraphData };

  return (
    <>
      {/** Google Tag Manager */}
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="zuma-gtm" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
        `}
      </Script>

      <Head>
        <link rel="shortcut icon" href="/images/ZL_Star.png" />

        {/* Add Open Graph <meta></meta> tags here */}
        {Object.keys(ogData).map((key) => (
          <meta property={key} content={ogData[key]} key={key} />
        ))}
      </Head>

      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </SessionContextProvider>
    </>
  );
}
